import Appshell from '../../../components/Appshell'
import Payment from '../../../components/screens/Payment'


const text = {
  note: 'Výborne! Pre osobu, ktorú chcete obdarovať, ste vybrali typ predplatného:',
  title: '3 mesiace (4/70)',
  description: `Na jej adresu príde každý mesiac balík štyroch dobrých kníh. Vaša platba bude najviac 70 eur,
  vrátane poštovného (alebo menej, v závislosti od knižného výberu v daný mesiac).`,
}

const Quaterly = () => {
  return (
    <Appshell>
      <Payment gift text={text} type="gift_quaterly" />
    </Appshell>
  )
}

export default Quaterly
